import React from "react";

import {
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Card, CardContent, CardFooter, CardHeader } from "../../Cards/Card";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

const VerticalChartGraphic = ({
  chartElements,
  chartElementsLimit = 0,
  data,
  headerText,
  xAxisTickFormatter,
  xAxisDomain,
  yAxisDataKey,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleModal = () => {
    setIsModalOpen((previousValue) => !previousValue);
  };

  const styles = {
    modalCol: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "0.7rem",
      height: `${screenHeight - 300}px`,
    },
    col: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "25rem",
      marginTop: "0.7rem",
    },
    fullScreenStyle: {
      maxWidth: "100%",
      width: "100vw",
      height: "100vh",
      margin: "0",
      top: "0",
    },
    cardFooter: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "16px",
    },
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        toggle={() => toggleModal()}
        style={styles.fullScreenStyle}
      >
        <ModalHeader toggle={() => toggleModal()}>
          Visualização em tela cheia
        </ModalHeader>
        <ModalBody style={{ height: `${screenHeight - 150}px` }}>
          <Card>
            <CardHeader
              dataToDownload={data}
              headerText={headerText}
              showExpandButton={true}
              showButtonsTooltips={false}
              handleExpandButtonClick={toggleModal}
            />
            <CardContent>
              <Row>
                <Col style={styles.modalCol}>
                  <VerticalGraphic
                    chartElements={chartElements}
                    data={data}
                    xAxisDomain={xAxisDomain}
                    xAxisTickFormatter={xAxisTickFormatter}
                    yAxisDataKey={yAxisDataKey}
                    yAxisWidth={200}
                  />
                </Col>
              </Row>
            </CardContent>
            <CardFooter style={styles.cardFooter}>
              <div></div>
              <img
                style={{ maxWidth: "100px", opacity: 0.5 }}
                src="/static/media/vitec.9bd71d52.png"
                alt="Logo do Vitec"
              />
            </CardFooter>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => toggleModal()}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
      <Card>
        <CardHeader
          dataToDownload={data}
          headerText={headerText}
          showExpandButton={true}
          showButtonsTooltips={true}
          handleExpandButtonClick={toggleModal}
        />
        <CardContent>
          <Row>
            <Col style={styles.col}>
              <VerticalGraphic
                chartElements={chartElements}
                chartElementsLimit={chartElementsLimit}
                data={data}
                xAxisDomain={xAxisDomain}
                xAxisTickFormatter={xAxisTickFormatter}
                yAxisDataKey={yAxisDataKey}
              />
            </Col>
          </Row>
        </CardContent>
        <CardFooter style={styles.cardFooter}>
          <div>
            {data &&
            data.length > 0 &&
            chartElementsLimit > 0 &&
            data.length > chartElementsLimit ? (
              <>
                <i>
                  <img
                    src={require("../../../assets/img/icons/interface/triangle-warning.png")}
                    style={{ width: "13px", height: "13px" }}
                    alt="icone"
                  />
                </i>{" "}
                <span style={{ fontSize: "12px" }}>
                  Os resultados foram limitados para não prejudicar a
                  visualização. Clique no botão "Expandir" para visualizar o
                  gráfico completo.
                </span>
              </>
            ) : null}
          </div>
          <img
            style={{ maxWidth: "100px", opacity: 0.5 }}
            src="/static/media/vitec.9bd71d52.png"
            alt="Logo do Vitec"
          />
        </CardFooter>
      </Card>
    </>
  );
};

const VerticalGraphic = ({
  chartElements,
  chartElementsLimit = 0,
  data,
  xAxisTickFormatter,
  xAxisDomain,
  yAxisDataKey,
  yAxisWidth = 150,
}) => {
  const [dataToRender, setDataToRender] = React.useState(data);

  React.useEffect(() => {
    if (!data || data.length === 0 || chartElementsLimit <= 0) return;

    if (chartElementsLimit <= 0) {
      setDataToRender(data);

      return;
    }

    const newDataToRender = [];

    for (let i = 0; i < chartElementsLimit; i++) {
      if (!data[i]) break;

      newDataToRender.push(data[i]);
    }

    setDataToRender(newDataToRender);
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        layout="vertical"
        width={500}
        height={400}
        data={dataToRender}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis
          type="number"
          tickFormatter={(value) =>
            `${Number(value.toFixed(0))}${xAxisTickFormatter}`
          }
          domain={xAxisDomain && xAxisDomain.length > 0 ? xAxisDomain : null}
        />
        <YAxis
          dataKey={yAxisDataKey}
          width={yAxisWidth}
          interval={0}
          type="category"
          scale="band"
          tick={{ dy: 5 }}
        />
        <Tooltip />
        <Legend />
        {!chartElements || chartElements.length === 0
          ? null
          : chartElements.map((chart) => chart)}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default VerticalChartGraphic;
