import request from "../utils/request";
//import request from "../utils/requestTests/requestTestDemand";

import { getUserData } from "../../services/utils/auth";

export function fetchTrapsDensity(filter) {
  return request({
    url: "/Traps/GetTrapsDensity",
    method: "post",
    data: filter,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}

export function  getActiveTraps(filter) {
  if (
    filter &&
    filter.organizationId &&
    filter.organizationId == "559a16ab-5da8-41ba-96b8-6e9f55feddd8"
  ) {
    filter.filterForReadTraps = true;
  }
  filter.webTraps = true;

  return request({
    url: "/Traps/ActiveTraps",
    method: "post",
    data: filter,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}

export function getTraps(filter) {
  return request({
    url: "/Traps/GetAll",
    method: "post",
    data: filter,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}

export function getTrapStatusNumbers(filter) {
  return request({
    url: "/Traps/TrapStatusNumbers",
    method: "post",
    data: filter,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}

export function getTrapEggNumbersFromLab(filter) {
  return request({
    url: "/Traps/TrapEggNumbersFromLab",
    method: "post",
    data: filter,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}

export function getTrapById(id) {
  return request({
    url: "/Traps/" + id,
    method: "get",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}

export function getTrapByNumber(trapNumber, organizationId) {
  let OrganizationId;

  if (organizationId) OrganizationId = organizationId;
  else OrganizationId = getUserData("organizationId");

  let dataSend = {
    OrganizationId: OrganizationId,
    Number: trapNumber,
  };

  return request({
    url: "/Traps/TrapByNumber",
    method: "post",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    data: dataSend,
  });
}

export function putTrapByNumber(data) {
  return request({
    url: "/Traps/UpdateTrapByNumber",
    method: "post",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    data: data,
  });
}

// Função desativada para evitar problemas futuros.
//
// export function deleteTrap(id) {
//   return request({
//     url: "/Traps/" + id,
//     method: "delete",
//     headers: new Headers({
//       "Content-Type": "application/json",
//     }),
//   });
// }

export function deleteTrapHistory(id) {
  return request({
    url: "/Traps/TrapHistory/" + id,
    method: "delete",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}

export function postTrap(TrapData) {
  return request({
    url: "/Traps",
    method: "post",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    data: TrapData,
  });
}

export function ImportTraps(TrapData) {
  return request({
    url: "/Traps/Import",
    method: "post",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    data: TrapData,
  });
}

export function putTrap(id, TrapData) {
  return request({
    url: "/Traps/" + id,
    method: "put",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    data: TrapData,
  });
}

export function getTrapsTypes() {
  var OrganizationId = getUserData("organizationId");
  return request({
    url: "/Traps/Types/" + OrganizationId,
    method: "get",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}

export function getAllTrapHistory(filter) {
  return request({
    url: "/Traps/AllTrapHistory",
    method: "post",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    data: filter,
  });
}

export function postTrapHistory(trapHistory) {
  return request({
    url: "/Traps/TrapHistory",
    data: trapHistory,
    method: "post",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}

export function getTrapHistory(trap) {
  var dataSend = {
    organizationId: trap.organizationId,
  };
  return request({
    url: "/Traps/TrapHistoryById/" + trap.id,
    method: "post",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    data: dataSend,
  });
}

export function getTrapsToMap(filter) {
  return request({
    url: "/Traps/TrapsToMap",
    method: "post",
    data: filter,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}

export function getActiveTrapsToMap(filter) {
  if (
    filter &&
    filter.organizationId &&
    filter.organizationId == "559a16ab-5da8-41ba-96b8-6e9f55feddd8"
  ) {
    filter.filterForReadTraps = true;
  }
  return request({
    url: "/Traps/TrapsToMap/Actives",
    method: "post",
    data: filter,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}

export function getTrapStatusCard(filter) {
  return request({
    url: "/Traps/TrapCardStatus",
    method: "post",
    data: filter,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}

export function getInstalledTrapsByVisitId(visitId) {
  return request({
    url: `/Traps/TrapsInstalledInVisit/${visitId}`,
    method: "get",
  });
}

export function updateHistories(histories) {
  return request({
    url: `/Traps/Histories`,
    method: "put",
    data: histories,
  });
}

export function getTrapsStatusByColor(filter) {
  return request({
    url: "/Traps/TrapStatusCache",
    method: "post",
    data: filter,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}

export function fetchTypingProductivity(filters) {
  return request({
    url: "/Traps/TypingProductivityByOrganization",
    method: "post",
    data: filters,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  })
}

export function fetchFieldProductivity(filters) {
  return request({
    url: "/Traps/TypingProductivityByOrganization",
    method: "post",
    data: filters,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  })
}

export function fetchTotalProductivity(filters) {
  return request({
    url: "/Traps/TotalProductivity",
    method: "post",
    data: filters,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  })
}

export function fetchTerritorializationReport(filters) {
  return request({
    url: "/Traps/TerritorializationReport",
    method: "post",
    data: filters,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  })
}

export function fetchGroupedTrapHistoriesByFilters(filters) {
  return request({
    url: "/Traps/GroupedTrapHistoriesByFilters",
    method: "post",
    data: filters,
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
}