import React from "react";

import { Col, Row } from "reactstrap";
import { Bar, Rectangle } from "recharts";
import PieChartGraphic from "../../../components/Graphics/Pie/PieChartGraphic";
import BarChartGraphic from "../../../components/Graphics/Bar/BarChartGraphic";
import moment from "moment";
import VerticalChartGraphic from "../../../components/Graphics/Composed/VerticalChartGraphic";

const ManagementPageGraphicsWrapper = ({ data, filtersValues }) => {
  const [formatedDataForGraphics, setFormatedDataForGraphics] = React.useState(
    []
  );

  React.useEffect(() => {
    if (!data || data.length === 0) return;

    const formatedData = data.map((report) => {
      return {
        "Armadilhas Instaladas": report.activeTraps,
        "Área em KM²": report.squareKilometre,
        "Área de Intervenção": report.territorializationName,
        "Densidade Armadilhas/KM²": report.trapsDensityBySquareKilometre,
        "Área de Risco": report.trapsNotProtectingAreas,
        "Área de Risco (%)": report.trapsNotProtectingAreasPercentage,
        "Armadilhas 1º Período": report.trapsOnFirstPeriod,
        "Armadilhas 1º Período (%)": report.trapsOnFirstPeriodPercentage,
        "Armadilhas 2º Período": report.trapsOnSecondPeriod,
        "Armadilhas 2º Período (%)": report.trapsOnSecondPeriodPercentage,
        "Armadilhas 3º Período": report.trapsOnThirdPeriod,
        "Armadilhas 3º Período (%)": report.trapsOnThirdPeriodPercentage,
        "Área Protegida": report.trapsProtectingAreas,
        "Área Protegida (%)": report.trapsProtectingAreasPercentage,
      };
    });

    setFormatedDataForGraphics(formatedData);
  }, [data]);

  const beginDateFormated =
    filtersValues &&
    filtersValues.beginDate &&
    moment(filtersValues.beginDate).format("DD/MM/YYYY");
  const finalDateFormated =
    filtersValues &&
    filtersValues.finalDate &&
    moment(filtersValues.finalDate).format("DD/MM/YYYY");

  return formatedDataForGraphics && formatedDataForGraphics.length > 0 ? (
    <>
      <Row className="mt-12 mb-12" style={{ paddingBottom: "1.5rem" }}>
        <Col className="mb-6 mb-xl-0" xl="6">
          <PieChartGraphic
            data={formatedDataForGraphics}
            dataKey="Armadilhas Instaladas"
            chartElementsLimit={10}
            customTooltip={<InstalledTrapsCustomTooltip />}
            headerText={
              <span>
                Armadilhas Instaladas ({filtersValues.trapType.label})<br /> em{" "}
                {beginDateFormated} a {finalDateFormated}
              </span>
            }
            legendsKey="Área de Intervenção"
            propertyToOrderBy="Armadilhas Instaladas"
          />
        </Col>
        <Col className="mb-6 mb-xl-0" xl="6">
          <PieChartGraphic
            data={formatedDataForGraphics}
            dataKey="Área em KM²"
            chartElementsLimit={10}
            customTooltip={<SquareKilometersCustomTooltip />}
            headerText={
              <span>
                Áreas em KM² ({filtersValues.trapType.label})<br /> em{" "}
                {beginDateFormated} a {finalDateFormated}
              </span>
            }
            legendsKey="Área de Intervenção"
            propertyToOrderBy="Área em KM²"
          />
        </Col>
      </Row>
      <Row className="mt-12 mb-12" style={{ paddingBottom: "1.5rem" }}>
        <Col className="mb-6 mb-xl-0" xl="6">
          <BarChartGraphic
            bars={[
              <Bar
                dataKey="Densidade Armadilhas/KM²"
                fill="#8884d8"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />,
            ]}
            data={formatedDataForGraphics}
            headerText={
              <span>
                Densidade de Armadilhas por KM² ({filtersValues.trapType.label})
                <br />
                em {beginDateFormated} a {finalDateFormated}
              </span>
            }
            keyToConsiderOnDomain={"Densidade Armadilhas/KM²"}
            xAxisDataKey="Área de Intervenção"
          />
        </Col>
        <Col className="mb-6 mb-xl-0" xl="6">
          <BarChartGraphic
            bars={[
              <Bar
                dataKey="Armadilhas 1º Período"
                fill="#0dd649"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />,
              <Bar
                dataKey="Armadilhas 2º Período"
                fill="#dcac00"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />,
              <Bar
                dataKey="Armadilhas 3º Período"
                fill="#f10a0a"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />,
            ]}
            data={formatedDataForGraphics}
            headerText={
              <span>
                Status da Manutenção em Valores Absolutos (
                {filtersValues.trapType.label})<br /> em {beginDateFormated} a
                {finalDateFormated}
              </span>
            }
            xAxisDataKey="Área de Intervenção"
          />
        </Col>
      </Row>
      <Row className="mt-12 mb-12" style={{ paddingBottom: "1.5rem" }}>
        <Col className="mb-6 mb-xl-0" xl="6">
          <VerticalChartGraphic
            chartElements={[
              <Bar
                dataKey="Armadilhas 1º Período (%)"
                fill="#0dd649"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
                stackId="a"
                barSize={20}
              />,
              <Bar
                dataKey="Armadilhas 2º Período (%)"
                fill="#dcac00"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
                stackId="a"
                barSize={20}
              />,
              <Bar
                dataKey="Armadilhas 3º Período (%)"
                fill="#f10a0a"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
                stackId="a"
                barSize={20}
              />,
            ]}
            chartElementsLimit={7}
            data={formatedDataForGraphics}
            headerText={
              <span>
                Status da Manutenção em Percentual (
                {filtersValues.trapType.label})<br /> em {beginDateFormated} a {" "}
                {finalDateFormated}
              </span>
            }
            propertyToOrderBy="Armadilhas 1º Período (%)"
            xAxisTickFormatter="%"
            xAxisDomain={[0, 100]}
            yAxisDataKey="Área de Intervenção"
          />
        </Col>
        <Col className="mb-6 mb-xl-0" xl="6">
          <BarChartGraphic
            bars={[
              <Bar
                dataKey="Área Protegida (%)"
                fill="#0dd649"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />,
              <Bar
                dataKey="Área de Risco (%)"
                fill="#f10a0a"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />,
            ]}
            data={formatedDataForGraphics}
            headerText={
              <span>
                Áreas de Risco x Áreas Protegidas (
                {filtersValues.trapType.label})<br /> em {beginDateFormated} a{" "}
                {finalDateFormated}
              </span>
            }
            xAxisDataKey="Área de Intervenção"
          />
        </Col>
      </Row>
    </>
  ) : null;
};

const InstalledTrapsCustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        <p className="label" style={{ fontWeight: "bold" }}>
          {data["Área de Intervenção"]}: {data["Armadilhas Instaladas"]} ADI's
        </p>
      </div>
    );
  }

  return null;
};

const SquareKilometersCustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        <p className="label" style={{ fontWeight: "bold" }}>
          {data["Área de Intervenção"]}: {data["Área em KM²"]}KM²
        </p>
      </div>
    );
  }

  return null;
};

export default ManagementPageGraphicsWrapper;
