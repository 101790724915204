import axios from 'axios';
import { getToken } from '../utils/auth';

// Cria a instância do Axios
const service = axios.create({
  baseURL: 'http://localhost:5007/api/',
  // baseURL: 'http://api.vitecweb.com.br/api/',
  timeout: 1000000, // Timeout
});

// request
service.interceptors.request.use(
  config => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + getToken(); // insere o token JWT 
    }

    return config;
  },
  error => {
    // Faz algo com o erro da request
    console.log(error); // para debug
    Promise.reject(error);
  }
);

export default service;
